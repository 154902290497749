import { render, staticRenderFns } from "./Demandpunchrow.vue?vue&type=template&id=1d820847&scoped=true&lang=pug&"
import script from "./Demandpunchrow.vue?vue&type=script&lang=ts&"
export * from "./Demandpunchrow.vue?vue&type=script&lang=ts&"
import style0 from "./Demandpunchrow.vue?vue&type=style&index=0&id=1d820847&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d820847",
  null
  
)

export default component.exports